export interface IconCheckEmblemProps {
	testId?: string;
	className?: string;
}

const IconCheckEmblem = (props: IconCheckEmblemProps) => {
	const { testId = 'IconCheckEmblem', className, ...remainingProps } = props;

	return (
		<svg
			data-testid={testId}
			className={className}
			xmlns="http://www.w3.org/2000/svg"
			width="26"
			height="32"
			viewBox="0 0 26 32"
			fill="none"
			aria-label="check badge icon"
			{...remainingProps}
		>
			<path d="M13 .068c-.268 0-.536.043-.791.129l-10 3.332A2.497 2.497 0 0 0 .5 5.901v13.88a7.485 7.485 0 0 0 3.34 6.24l6.733 4.488a4.37 4.37 0 0 0 4.854 0l6.733-4.489a7.485 7.485 0 0 0 3.34-6.24V5.9c0-1.077-.686-2.03-1.709-2.371l-10-3.332A2.506 2.506 0 0 0 13 .068Zm0 2.503a.07.07 0 0 1 .02.002l9.937 3.313a.064.064 0 0 1 .043.06V19.78a4.998 4.998 0 0 1-2.227 4.16L14.04 28.43a1.87 1.87 0 0 1-2.08 0L5.227 23.94A4.998 4.998 0 0 1 3 19.78V5.946c0-.027.018-.051.043-.06l9.938-3.313A.067.067 0 0 1 13 2.571Zm5.602 5.803a1.249 1.249 0 0 0-1.027.57l-6.068 9.367-3.193-3.63a1.249 1.249 0 1 0-1.878 1.65l4.285 4.87a1.253 1.253 0 0 0 1.03.421 1.25 1.25 0 0 0 .957-.566l6.966-10.752a1.25 1.25 0 0 0-1.072-1.93Z" />
		</svg>
	);
};

export default IconCheckEmblem;
